





























import { Component, Vue } from 'vue-property-decorator';

import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCancelTelegram, dispatchGetTelegramStatus, dispatchRunTelegram } from '@/store/telegram/actions';
import { readTelegramFinished, readTelegramState } from '@/store/telegram/getters';
import { commitSetTelegramFinished } from '@/store/telegram/mutations';
import { delay } from '@/utils';
import StartButton from '../../../components/StartButton.vue';

const TG_STATUS_ACTIVE = 1;
const TG_STATUS_INERVAL = 30000; // 30 seconds

@Component({
  components: {
    StartButton,
  },
})
export default class Telegram extends Vue {
  public timeOutPointer?: number = undefined;

  get tgState() {
    return readTelegramState(this.$store);
  }

  get isFinished() {
    return readTelegramFinished(this.$store);
  }

  public async handleGetStat() {
    await dispatchRunTelegram(this.$store);
    await this.checkTelegramStatus();
  }

  public setFinished(value: boolean = true) {
    commitSetTelegramFinished(this.$store, value);
  }

  public async checkTelegramStatus(cancelled = false) {
    await dispatchGetTelegramStatus(this.$store);
    if (this.tgState.status === TG_STATUS_ACTIVE) {
      this.setFinished();
      clearInterval(this.timeOutPointer);
      this.timeOutPointer =  setTimeout(() => this.checkTelegramStatus(), TG_STATUS_INERVAL);
    } else {
      this.tgState.status = null;
      if (this.isFinished) {
        const content = cancelled ? 'Telegram: задача отменена'
          : `Проверено ${this.tgState.done} каналов из ${this.tgState.total}`;
        commitAddNotification(this.$store, { content, color: 'info' });
        this.setFinished(false);
      }
    }
  }

  public async mounted() {
    this.checkTelegramStatus();
  }

  public destroyed() {
    clearInterval(this.timeOutPointer);
  }

  public async handleCancelTask() {
    this.tgState.status = null;
    // используем delay, чтобы бэк успел обновить статус в redis
    const res = await Promise.all([dispatchCancelTelegram(this.$store), delay(500)]);
    if (res) {
      clearInterval(this.timeOutPointer);
      await this.checkTelegramStatus(true);
    }
  }
}
